import { createStore } from 'vuex'

import theme from './modules/theme'
import moduleInit from './modules/init'
import botSetting from './modules/botSetting'
import chat from './modules/chat'
import botProfile from './modules/botProfile'
import report from './modules/report'

const store = createStore({
  modules: {
    theme: theme,
    init: moduleInit,
    botSetting: botSetting,
    chat,
    botProfile,
    report
  },
  strict: process.env.NODE_ENV !== 'production'
});

store.dispatch('init/init').then(() => {}).catch(() => {});

export default store

